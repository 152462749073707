/**
Componente compoe a tabela de monitoramento de linhas, contém as informações do segundo SlideUpDown
*/
<template>
    <div class="col-sm-12 nopadding divMasterTimeline">
        <div class="pb-1" style="background-color: #eeeeee;">
            <div class="row align-items-center p-1" style="border-bottom: 1px solid black;">
                <div class="col d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiTransitConnectionVariant" />
                    <span style="font-size: 10px; font-weight: bold;">Detalhes</span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Status:</span>
                    <div style="user-select: none;">
                        <b-col
                            v-b-popover.hover.top="arrayDados.status == 'I' ? ('Iniciada') : (arrayDados.status == 'F' ? ('Finalizada') : (arrayDados.status == 'P' ? ('Pendente') : (arrayDados.status == 'C' ? ('Cancelada') : (''))))"
                            class="font-bold border border-warning-subtle bolinhaAzul">
                            <p style="margin-top: -5px; margin-left: 1px;">
                                {{ arrayDados.status }}
                            </p>
                        </b-col>
                    </div>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Percorrido:</span>
                    <span style="font-weight: bold; font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.percorrido + ' km'">
                        {{ arrayDados.percorrido }} km
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span style="font-weight: bold; font-size: 10px;" v-b-popover.hover.top="arrayDados.turno">
                        {{ arrayDados.turno }}
                    </span>
                </div>
            </div>

            <div class="row align-items-center p-1" style="border-bottom: 1px solid black;">
                <div class="col d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiClockCheckOutline" />
                    <span class="font-bold" style="font-size: 10px;">Tempo em execução</span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Tempo produtivo:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.tempoExecucao.tempoProdutivo">
                        {{ arrayDados.tempoExecucao.tempoProdutivo }}
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Tempo improdutivo:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.tempoExecucao.tempoImprodutivo">
                        {{ arrayDados.tempoExecucao.tempoImprodutivo }}
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Tempo de execução:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.tempoExecucao.tempoExecucao">
                        {{ arrayDados.tempoExecucao.tempoExecucao }}
                    </span>
                </div>
            </div>

            <div class="row align-items-center p-1" style="border-bottom: 1px solid black;">
                <div class="col d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiCarTractionControl" />
                    <span class="font-bold" style="font-size: 10px;">Km produtivo / improdutivo</span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Km produtivo:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.kmProdutivoImprodutivo.kmProdutivo + ' km'">
                        {{ arrayDados.kmProdutivoImprodutivo.kmProdutivo }} km
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Km improdutivo:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.kmProdutivoImprodutivo.kmImprodutivo + ' km'">
                        {{ arrayDados.kmProdutivoImprodutivo.kmImprodutivo }} km
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Média de consumo:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.kmProdutivoImprodutivo.mediaConsumo + ' km/L'">
                        {{ arrayDados.kmProdutivoImprodutivo.mediaConsumo }} km/L
                    </span>
                </div>
            </div>

            <div class="d-flex align-items-center p-1 col-sm-12" style="border-bottom: 1px solid black;">
                <div class="col-sm-3 d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiBusMarker" />
                    <span class="font-bold" style="font-size: 10px;">Transbordo</span>
                </div>

                <div class="col-sm-3 d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Descarga (ponto):</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.totalDescargas == null ? ('0 pontos') : arrayDados.totalDescargas + ' pontos'">
                        {{ arrayDados.totalDescargas == null ? 0 : arrayDados.totalDescargas }} pontos
                    </span>
                </div>

                <div class="col-sm-3 d-flex align-items-center">
                    <span class="mr-2" style="font-size: 10px;">Peso:</span>
                    <span class="font-bold" style="font-size: 10px;"
                        v-b-popover.hover.top="arrayDados.rctpesagem + 'kg'">
                        {{ arrayDados.rctpesagem }} kg
                    </span>
                </div>

                <div class="col-sm-3 d-flex align-items-center" @click="abrirMapaCollapse(true, true)">
                    <baseIcon size='25' style="cursor: pointer" :icon="mdiMapPlus" />
                </div>
            </div>

            <div class="d-flex align-items-center p-1 col-sm-12" style="border-bottom: 1px solid black;">
                <div class="col-sm-3 d-flex align-items-center">
                    <baseIcon size="18" class="mr-2" style="color: #000" :icon="mdiAccountGroup" />
                    <span class="font-bold" style="font-size: 10px;">Ajudantes</span>
                </div>

                <div class="col-sm-9">
                    <template v-if="arrayDados.ajudantes && arrayDados.ajudantes.length > 0">
                        <div class="d-flex flex-wrap ">
                            <span v-for="(ajudante, index) in arrayDados.ajudantes" :key="index"
                                style="font-size: 10px;" class="mr-1 font-bold">
                                {{ ajudante }}{{ index < arrayDados.ajudantes.length - 1 ? ',' : '' }} </span>
                        </div>
                    </template>
                    <template v-else>
                        <span style="font-size: 10px; font-style: italic; color: #666;">
                            Sem ajudantes
                        </span>
                    </template>
                </div>
            </div>


            <div v-if="arrayDados.totalPontos >= 1" class="col-sm-12 timeLineTime mt-3">
                <div class="d-flex justify-content-between align-items-center" style="border-bottom: 1px solid black;">
                    <!-- Posição que o veículo esta em relação aos pontos  -->
                    <div class="col-sm-12 thisIsTheBusao">
                        <div :class="'col-sm-12 timeLineBusao statusPontoProgress-' + rotas.statusOnibus"
                            :style="'width:' + rotas.posicaoBusao + '%'">
                            <!-- mdiBusSide -->
                            <baseIcon size='45' class="iconBusao animeBusao" :icon="mdiBusSide" />
                        </div>
                    </div>
                    <!-- Ícones dos pontos -->
                    <div class="col-sm-12 thisIsTimeLine" style="">
                        <ponto @pontoCriado="pontoCriado" :tamanho="(100 / rotas.totalPontos)"
                            :key="rotas.codigo + '|' + ponto.ponto" v-for="(  ponto  ) in rotas.pontos  "
                            :arrayDados="ponto">
                        </ponto>
                    </div>
                </div>
            </div>

            <div v-if="Object.keys(arrayDados.regioes).length > 0"
                style="background-color: white; border: 2px solid #a6cfff; margin: 5px">
                <p class="font-bold mt-1 ml-2" style="font-size: 10px; margin-bottom: -1px;">Setores:</p>

                <div class="d-flex justify-content-center align-items-center position-relative pb-2"
                    style="width: 100%; margin: auto;">
                    <div style="height: 1px; background-color: black; width: 100%;" />

                    <div v-for="(key, index) in Object.keys(arrayDados.regioes)" v-bind:key="index">
                        <div class="d-flex justify-content-center align-items-center position-relative">
                            <div v-if="arrayDados.regioes[key].informacoesRegiao != null">
                                <div @click="toggleCollapse(index)"
                                    class="d-flex justify-content-center align-items-center position-relative" :style="{
                                        'background-color': arrayDados.regioes[key].informacoesRegiao.corRegiao
                                    }" style="border-radius: 5px; padding: 5px; cursor: pointer; width: max-content;">
                                    <p class="font-bold m-0 text-truncate"
                                        style="text-align: center; font-size: 10px; color: black">
                                        {{ arrayDados.regioes[key].informacoesRegiao.nomeRegiao }}
                                    </p>
                                </div>

                                <b-collapse v-model="activeCollapseIndex[index]" :id="'collapse-' + index"
                                    class="position-absolute mt-1"
                                    style="z-index: 1050; width: max-content; max-width: 500px; background-color: white; border: 2px solid rgb(156 163 175);">

                                    <div v-if="arrayDados.regioes[key].informacoesRegiao.tempoNoSetor">
                                        <div class="p-2">
                                            <div class="d-flex justify-content-between align-items-start">
                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiClockCheckOutline" />
                                                    <p style="font-size: 13px">Tempo no setor: </p>
                                                    <p class="font-bold" style="font-size: 13px">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.tempoNoSetor }}</p>
                                                </div>

                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiCarTractionControl" />
                                                    <p style="font-size: 13px">Km percorrido: </p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.kmPercorrido }} km
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-start">
                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiArrowCollapseRight" />
                                                    <p style="font-size: 13px">Entrada</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.dataHoraEntrada }}
                                                    </p>
                                                </div>

                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <p style="font-size: 13px">Hodômetro:</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.hodometroEntrada }}
                                                        Km/h</p>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-start">
                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiArrowCollapseLeft" />
                                                    <p style="font-size: 13px">Saída</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.dataHoraSaida }}
                                                    </p>
                                                </div>

                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <p style="font-size: 13px">Hodômetro:</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.hodometroSaida }}
                                                        Km/h
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-for="(transbordo, index) in arrayDados.regioes[key].transbordos"
                                            style="margin-top: -8px;" :key="index">
                                            <div style="border-top: 2px solid rgb(156 163 175);"
                                                class="row justify-content-center align-items-center text-truncate">
                                                <baseIcon size="17" style="color: #000" :icon="mdiBusMarker"
                                                    class="ml-2" />

                                                <div class="d-flex align-items-center pt-3 mx-2">
                                                    <p style="font-size: 13px" class="mb-3">Transbordo {{ index + 1 }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center pt-3 mx-2">
                                                    <p style="font-size: 13px" class="mr-2 mb-3">Ticket: </p>
                                                    <p style="font-size: 13px" class="font-bold mb-3">
                                                        {{ transbordo.ticket }}</p>
                                                </div>
                                                <div class="d-flex align-items-center pt-3 mx-2">
                                                    <p style="font-size: 13px" class="mr-2 mb-3">Peso: </p>
                                                    <p style="font-size: 13px" class="font-bold mb-3">
                                                        {{ transbordo.peso }} Kg</p>
                                                </div>
                                                <div class="d-flex align-items-center pt-3 mx-2 text-truncate"
                                                    style="max-width: 150px"
                                                    v-b-popover.hover.top="'Descarga (ponto): ' + transbordo.descarga">
                                                    <p style="font-size: 13px" class="mr-2 mb-3">Descarga (ponto): </p>
                                                    <p style="font-size: 13px" class="font-bold mb-3">{{
                                                        transbordo.descarga }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="p-2">
                                            <p class="font-bold">Veículo ainda não passou na região</p>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>

                            <div v-if="arrayDados.regioes[key].informacoesRegiao != null && index < (Object.keys(arrayDados.regioes).length) - 1"
                                class="d-flex justify-content-center align-items-center position-relative">
                                <div style="height: 1px; background-color: black; width: 30px;" />

                                <div class="estrela-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="19" height="19"
                                        class="estrela">
                                        <polygon points="12 2 15 9 22 12 15 15 12 22 9 15 2 12 9 9 12 2" fill="black" />
                                    </svg>
                                </div>
                                <div style="height: 1px; background-color: black; width: 30px;" />
                            </div>
                        </div>
                    </div>

                    <div style="height: 1px; background-color: black; width: 100%;" />
                </div>
            </div>

            <div v-if="arrayDados.mapaAberto">
                <mapaSimples height="400" ref="mapaSimplesMonitoramento">
                    <polilinhaCorDiferente :colorPolilyne="corRotaPlanejada" :latLng="rastroRotaPlanejada"
                        :poliEditavel="false" />

                    <polilinhaCorDiferente :colorPolilyne="corRotaRealizada" :latLng="rastroRotaRealizada"
                        :poliEditavel="false" />

                    <l-marker :lat-lng="[ultimaPosicaoVeiculo.latitude, ultimaPosicaoVeiculo.longitude]"
                        :icon="gerarIconeMapaVeiculo()">
                        <!-- <PopupMarker>
                            <div class="col-sm-12">
                                <div class="d-flex d-flex justify-content-between">Descarga(ponto):
                                    <span class="font-bold ml-2">{{ ultimaPosicaoVeiculo.direcao }} </span>
                                </div>
                            </div>
                        </PopupMarker> -->
                    </l-marker>

                    <span v-for="(regiao, index) in arrayDados.regioes" :key="index">
                        <span v-for="(transbordo, indexTransbordo) in regiao.transbordos" :key="indexTransbordo">
                            <l-marker :lat-lng="[transbordo.latitude, transbordo.longitude]" :icon="gerarIconeMapa()">
                                <PopupMarker>
                                    <div class="col-sm-12">
                                        <div class="d-flex d-flex justify-content-between">Descarga(ponto):
                                            <span class="font-bold ml-2">{{ transbordo.descricao }} </span>
                                        </div>

                                        <div class="d-flex d-flex justify-content-between">Ticket:
                                            <span class="font-bold ml-2">{{ transbordo.ticket }} </span>
                                        </div>

                                        <div class="d-flex d-flex justify-content-between">Pesagem:
                                            <span class="font-bold ml-2">{{ transbordo.peso }} Kg </span>
                                        </div>
                                    </div>
                                </PopupMarker>
                            </l-marker>
                        </span>

                        <poligono :poligono="tratarDadosRegiao(regiao.informacoesRegiao)">
                            <PopupMarker>
                                <div class="col-sm-12">
                                    <div class="d-flex justify-content-between">Região:
                                        <span class="font-bold ml-2">{{ regiao.informacoesRegiao.nomeRegiao }}
                                        </span>
                                    </div>

                                    <div class="d-flex justify-content-between">Entrada:
                                        <span class="font-bold ml-2">{{ regiao.informacoesRegiao.dataHoraEntrada }}
                                        </span>
                                    </div>

                                    <div class="d-flex justify-content-between">Saída:
                                        <span class="font-bold ml-2">{{ regiao.informacoesRegiao.dataHoraSaida }}
                                        </span>
                                    </div>

                                    <div class="d-flex justify-content-between">Hodômetro entrada:
                                        <span class="font-bold ml-2">{{ regiao.informacoesRegiao.hodometroEntrada }}
                                            km/h
                                        </span>
                                    </div>

                                    <div class="d-flex justify-content-between">Hodômetro saída:
                                        <span class="font-bold ml-2">{{ regiao.informacoesRegiao.hodometroSaida }}
                                            km/h
                                        </span>
                                    </div>
                                </div>
                            </PopupMarker>
                        </poligono>
                    </span>
                </mapaSimples>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mdiBusSide,
    mdiCarBack,
    mdiTransitConnectionVariant,
    mdiClockCheckOutline,
    mdiCarTractionControl,
    mdiBusMarker,
    mdiAccountGroup,
    mdiLogout,
    mdiLogin,
    mdiArrowCollapseRight,
    mdiArrowCollapseLeft,
    mdiMapPlus,
    mdiMapMarkerCircle,
    mdiOfficeBuildingMarker,
} from '@mdi/js'
import Vue from 'vue'
import { LMarker } from "vue2-leaflet";

// eslint-disable-next-line no-unused-vars
const decodePoly = require('@mapbox/polyline')

export default Vue.extend({
    name: 'MonitoramentoLinhaSeparada',

    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'ponto': require('./MonitoramentoLinhaPonto').default,
        'mapaSimples': require('@/components/Atom/Mapa/MapaSimplesTeste.vue').default,
        polilinhaCorDiferente: require("@/components/Atom/Mapa/PolilinhaCorDiferente").default,
        LMarker,
        'PopupMarker': require('@/components/Atom/Mapa/PopupMarker').default,
        poligono: require("@/components/Atom/Mapa/Poligonos").default,
    },

    data() {
        return {
            mdiBusSide: mdiBusSide,
            mdiCarBack: mdiCarBack,
            mdiMapPlus: mdiMapPlus,
            mdiMapMarkerCircle: mdiMapMarkerCircle,
            mdiOfficeBuildingMarker: mdiOfficeBuildingMarker,
            actived: false,
            pontosCriados: 0,
            arrayPontos: [],
            rotas: this.arrayDados,
            mdiTransitConnectionVariant: mdiTransitConnectionVariant,
            mdiClockCheckOutline: mdiClockCheckOutline,
            mdiCarTractionControl: mdiCarTractionControl,
            mdiBusMarker: mdiBusMarker,
            mdiAccountGroup: mdiAccountGroup,
            mdiLogout: mdiLogout,
            mdiLogin: mdiLogin,
            popoverSetores: false,
            activeCollapseIndex: {},
            mdiArrowCollapseLeft: mdiArrowCollapseLeft,
            mdiArrowCollapseRight: mdiArrowCollapseRight,
            abrirMapa: false,
            corRotaPlanejada: '#0000FF',
            corRotaRealizada: '#90EE90',
            rastroRotaPlanejada: [],
            rastroRotaRealizada: [],
            coordenadasRegiao: {},
            ultimaPosicaoVeiculo: {
                latitude: '',
                longitude: '',
                direcao: 0,
                ignicao: ''
            },
        }
    },

    props: {
        arrayDados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    },

    methods: {
        pontoCriado(ponto) {
            if (this.pontosCriados == (this.rotas.totalPontos - 1)) {
                this.arrayPontos[ponto.arrayDados.ponto] = ponto;
                this.$emit('linhaSeparadaCriada', this)
            } else {
                this.arrayPontos[ponto.arrayDados.ponto] = ponto;
                this.pontosCriados++;
            }
        },

        /**
         * Alterna o estado de um colapso específico e fecha os demais.
         *
         * @param {number} index - O índice do colapso que será aberto ou fechado.
         * @returns {void}
         * @author Yuri 🇧🇷
         */
        toggleCollapse(index) {
            this.$set(this.activeCollapseIndex, index, !this.activeCollapseIndex[index]);

            for (let key in this.activeCollapseIndex) {
                if (key != index) {
                    this.$set(this.activeCollapseIndex, key, false);
                }
            }
        },

        /**
         * @description Alterna o estado do mapa (abre ou fecha) e decodifica a rota planejada para o rastro.
         * @return {void}
         * @author Yuri 🇧🇷
         */
        abrirMapaCollapse(verificarStatusMapa = true, atualizarDados = false) {
            if (atualizarDados) {
                localStorage.setItem('atualizandoDados', '0')
            }

            if (verificarStatusMapa) {
                // eslint-disable-next-line vue/no-mutating-props
                this.arrayDados.mapaAberto = !this.arrayDados.mapaAberto;
            }

            if (this.arrayDados.mapaAberto) {
                this.$nextTick(() => {
                    this.carregarDadosMapa();
                });
            }
        },

        /**
         * @description Carrega os dados do mapa, decodifica a rota planejada e ajusta a visualização do mapa para a área da rota.
         * @return {void}
         * @author Yuri 🇧🇷
         */
        carregarDadosMapa() {
            this.rastroRotaPlanejada = decodePoly.decode(this.arrayDados.rastroRotaPlanejada);
            this.rastroRotaRealizada = this.arrayDados.rastroRotaRealizada;
            const rotasPlanejadaRealizada = this.rastroRotaPlanejada.concat(this.rastroRotaRealizada);

            if (this.arrayDados.ultimaPosicaoVeiculo.posicao != null) {
                const [latitude, longitude] = this.arrayDados.ultimaPosicaoVeiculo.posicao.split(",").map(Number);
                this.ultimaPosicaoVeiculo.latitude = latitude;
                this.ultimaPosicaoVeiculo.longitude = longitude;
            }

            if (this.arrayDados.ultimaPosicaoVeiculo.direcao != null) {
                this.ultimaPosicaoVeiculo.direcao = this.arrayDados.ultimaPosicaoVeiculo.direcao;
            }

            if (this.arrayDados.ultimaPosicaoVeiculo.ignicao != null) {
                this.ultimaPosicaoVeiculo.ignicao = this.arrayDados.ultimaPosicaoVeiculo.ignicao;
            }

            const atualizandoDados = localStorage.getItem('atualizandoDados');

            if (atualizandoDados == '0') {
                const mapaRef = this.$refs.mapaSimplesMonitoramento;
                if (mapaRef && typeof mapaRef.flyToBounds === 'function') {
                    mapaRef.flyToBounds(rotasPlanejadaRealizada);
                }
            }
        },

        /**
        * @description Retorna o html para mostrar um marker no mapa.
        * @param ordem
        * @return {string} html do marker
        * @author Yuri 🇧🇷
        */
        gerarIconeMapa() {
            // eslint-disable-next-line no-undef
            return L.divIcon({
                className: 'custom-div-icon',
                html: `<div>

           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35" fill="#22c55e">
            <title>map-marker-radius</title>
            <path d="M12,2C15.31,2 18,4.66 18,7.95C18,12.41 12,19 12,19C12,19 6,12.41 6,7.95C6,4.66 8.69,2 12,2M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M20,19C20,21.21 16.42,23 12,23C7.58,23 4,21.21 4,19C4,17.71 5.22,16.56 7.11,15.83L7.75,16.74C6.67,17.19 6,17.81 6,18.5C6,19.88 8.69,21 12,21C15.31,21 18,19.88 18,18.5C18,17.81 17.33,17.19 16.25,16.74L16.89,15.83C18.78,16.56 20,17.71 20,19Z" />
            </svg>

                </div>`,
                iconSize: [60, 40],
                iconAnchor: [32, 32],
            });
        },

        /**
        * @description Retorna o html para mostrar um marker no mapa.
        * @param ordem
        * @return {string} html do marker
        * @author Yuri 🇧🇷
        */
        gerarIconeMapaVeiculo() {
            this.ultimaPosicaoVeiculo.direcao = 7;

            if (this.ultimaPosicaoVeiculo == 1) {
                // eslint-disable-next-line no-undef
                return L.divIcon({
                    className: 'custom-div-icon',
                    html: `
                <div>

                ${this.ultimaPosicaoVeiculo.direcao == 0 ? (`
                <img style="width: 15px !important; height: 40px !important;" src='img/CLigada0.png' />
                `) : (
                            this.ultimaPosicaoVeiculo.direcao == 1 ? (`
                    <img src='img/CLigada1.png' />
                    `) : (
                                this.ultimaPosicaoVeiculo.direcao == 2 ? (`<img style="width: 40px !important; height: 14px !important;" src='img/CLigada2.png' />`) : (
                                    this.ultimaPosicaoVeiculo.direcao == 3 ? ("<img src='img/CLigada3.png' />") : (
                                        this.ultimaPosicaoVeiculo.direcao == 4 ? (`<img style="width: 15px !important; height: 40px !important;" src='img/CLigada4.png' />`) : (
                                            this.ultimaPosicaoVeiculo.direcao == 5 ? ("<img src='img/CLigada5.png' />") : (
                                                this.ultimaPosicaoVeiculo.direcao == 6 ? (`<img style="width: 40px !important; height: 15px !important;" src='img/CLigada6.png' />`) : (
                                                    this.ultimaPosicaoVeiculo.direcao == 7 ? ("<img src='img/CLigada7.png' />") : ('')
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )}
                </div>`,
                    iconSize: [40, 40],
                    iconAnchor: [32, 32],
                });
            } else {
                // eslint-disable-next-line no-undef
                return L.divIcon({
                    className: 'custom-div-icon',
                    html: `
                <div>

                ${this.ultimaPosicaoVeiculo.direcao == 0 ? (`
                <img style="width: 15px !important; height: 40px !important;" src='img/CDesligada0.png' />
                `) : (
                            this.ultimaPosicaoVeiculo.direcao == 1 ? (`
                    <img src='img/CDesligada1.png' />
                    `) : (
                                this.ultimaPosicaoVeiculo.direcao == 2 ? (`<img style="width: 40px !important; height: 14px !important;" src='img/CDesligada2.png' />`) : (
                                    this.ultimaPosicaoVeiculo.direcao == 3 ? ("<img src='img/CDesligada3.png' />") : (
                                        this.ultimaPosicaoVeiculo.direcao == 4 ? (`<img style="width: 15px !important; height: 40px !important;" src='img/CDesligada4.png' />`) : (
                                            this.ultimaPosicaoVeiculo.direcao == 5 ? ("<img src='img/CDesligada5.png' />") : (
                                                this.ultimaPosicaoVeiculo.direcao == 6 ? (`<img style="width: 40px !important; height: 15px !important;" src='img/CDesligada6.png' />`) : (
                                                    this.ultimaPosicaoVeiculo.direcao == 7 ? ("<img src='img/CDesligada7.png' />") : ('')
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )}
                    
                </div>`
                });
            }
        },

        /**
         * @description Processa os dados de uma região e retorna um objeto com suas propriedades.
         * @param {Object} regiao - Objeto contendo as informações da região.
         * @return {Object} Objeto formatado com as propriedades da região.
         * @author Yuri 🇧🇷
         */
        tratarDadosRegiao(regiao) {
            const result = regiao.area.map(obj => [Number(obj.rclatitude), Number(obj.rclongitude)]);

            return new Object({
                latlngs: result,
                color: regiao.corRegiao,
                descricao: regiao.nomeRegiao,
                codigo: regiao.codigoRegiao,
                velocidade: regiao.velocidade,
                risco: regiao.risco,
            });
        }
    },

    mounted() {
        this.$emit('linhaSeparada', this.rotas.codigo);
    },

    watch: {
        arrayDados() {
            this.rotas = this.arrayDados;
        },

        'arrayDados.rastroRotaPlanejada': {
            handler(newVal) {
                this.rastroRotaPlanejada = [...decodePoly.decode(newVal)];
            },
            deep: true,
        },
    },
})
</script>

<style lang="css" scoped>
div.font-bold {
    font-weight: bold !important;
}

.bolinhaAzul {
    background-color: #a6cfff;
    padding: 6.5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.5));
}

.estrela-container {
    padding: 0;
    margin: 0;
    margin-left: -3px;
    margin-right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.estrela {
    margin: 0;
    padding: 0;
}
</style>
