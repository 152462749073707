<template>
    <panelEagle id="detalhesLinha" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiTransitConnectionVariant" titulo="Detalhes linhas"></tituloPage>
                </div>

                <div class="col-sm-6 divButtonsBasic d-flex align-items-center justify-content-end">
                    <div class="btnLegenda">
                        <simpleButton id="popoverInfo" width="100px" :icon="mdiAlertCircleOutline" type="orange"
                            text="Legenda" title="Legenda">
                        </simpleButton>
                    </div>

                    <basicButtonsRelatoriosEIG :disabledDropdown="!Object.keys(dadosRelatorio).length"
                        @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRelatorio"
                        :loading='loadingExportar' :disabled="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>

            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr>
                    </div>
                    <div class="col-sm-12 row justpaddingbottom">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit @changeInput="changeData" name="inputData" opens='right'>
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll @changeSelect="SelectEmpresas" :isMultiple="false" nameForRadio="radiosEmpresas"
                                :labels="labelEmpresas" :optionsArray="optionsEmpresas" :selected="selectedEmpresa"
                                firstSelected="EM" :hasSelectAll="false">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll @selectCriado="SelectCriadoVLLP" @changeCheck="changeCheck"
                                @changeSelect="selectLinhasVeiculos" :isMultiple=true
                                nameForRadio="radiosVeiculosLinhas" :labels="[
                                    { indexDFH: 'V', description: regioesSelecionadas.length > 0 ? ('Veículos') : ('Veículos *') },
                                    { indexDFH: 'L', description: regioesSelecionadas.length > 0 ? ('Linhas') : ('Linhas *') },
                                    { indexDFH: 'LP', description: regioesSelecionadas.length > 0 ? ('Linhas principais') : ('Linhas principais *') },
                                ]" :optionsArray="optionsVLLP" firstSelected="V" :hasSelectAll="true"
                                :loading="carregando" :disabled="$v.selectedEmpresas.$invalid">
                            </selectAll>
                        </div>

                        <div class="col-sm-2 nopadding">
                            <selectAll :loading="loadingRegioes" @changeSelect="changeRegiao" :isMultiple="true"
                                nameForRadio="radiosRegioes"
                                :labels="[{ indexDFH: 'RG', description: selectedVLLP.length != 0 ? ('Regiões') : ('Regiões *') }]"
                                :optionsArray="regioes" :selected="selectedRegioes" firstSelected="RG"
                                :hasSelectAll="true" :disabled="$v.selectedEmpresas.$invalid">
                            </selectAll>
                        </div>

                        <div class="col-sm-2 nopadding mt-3">
                            <selectAll @changeSelect="selectTurnos" :isMultiple="true" nameForRadio="radiosTurnos"
                                :labels="labelTurnos" :optionsArray="optionsTurnos" :selected="selectedEmpresa"
                                firstSelected="TR" :hasSelectAll="true" :disabled="$v.selectedEmpresas.$invalid">
                            </selectAll>
                        </div>

                        <div class="col-sm-3 nopadding mt-3">
                            <buttonsFilter @buttonsFiltersChange="changeFiltros" :arrayButtons="arrayButtons"
                                label="Filtros" :onlyOneSelected="false">
                            </buttonsFilter>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>

            <div class="col-sm-12 divHrDeBaixo">
                <hr>
            </div>
        </div>

        <span v-if="comPontos">
            <TabelaDetalhesLinhasComPontos :tamanhoRelatorio="tamanhoRelatorio" :statusbar="statusbar"
                :dadosRelatorio="dadosRelatorio" :actived="actived" />
        </span>

        <span v-else>
            <TabelaDetalhesLinhasSemPontos :tamanhoRelatorio="tamanhoRelatorio" :statusbar="statusbar"
                :dadosRelatorio="dadosRelatorio" :actived="actived" :totalizadoresTodosDias="totalizadoresTodosDias" />
        </span>

        <div>
            <b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-12 nopadding">
                        <subtitle :items="itemsSubtitleLinha" title="Operação">
                        </subtitle>
                    </div>
                    <div class="col-sm-12 nopadding">
                        <subtitle :items="itemsSubtitleStatusLinha" title="Siglas">
                        </subtitle>
                    </div>
                </div>
            </b-popover>
        </div>
    </panelEagle>
</template>

<script>
import {
    mdiTransitConnectionVariant,
    mdiAlertCircleOutline,
} from '@mdi/js'
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'DetalhesLinhas',

    components: {
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'inputRangeWithLimit': require('@/components/Atom/Datas/InputRangeWithLimit').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'buttonsFilter': require('@/components/Atom/Buttons/ButtonsFilters').default,
        'subtitle': require('@/components/Atom/Subtitle/Subtitle').default,
        'TabelaDetalhesLinhasComPontos': require('@/components/Atom/SpecificComponents/DetalhesLinhas/TabelaDetalhesLinhasComPontos').default,
        'TabelaDetalhesLinhasSemPontos': require('@/components/Atom/SpecificComponents/DetalhesLinhas/TabelaDetalhesLinhasSemPontos').default,
    },

    validations() {
        return {
            selectedEmpresas: { required },
            selectedVLLP: {
                required: () => this.regioesSelecionadas.length != 0 || this.selectedVLLP.length > 0,
            },
        };
    },

    data() {
        return {
            mdiAlertCircleOutline: mdiAlertCircleOutline,
            mdiTransitConnectionVariant: mdiTransitConnectionVariant,
            datas: '',
            dataDoRelatorio: '',
            labelEmpresas: [{ indexDFH: 'EM', description: 'Empresa *' }],
            labelTurnos: [{ indexDFH: 'TR', description: 'Turno' }],
            optionsEmpresas: new EmpresasService().Get(),
            optionsTurnos: [
                { 'value': 'P', 'description': '1° Turno' },
                { 'value': 'S', 'description': '2° Turno' },
                { 'value': 'T', 'description': '3° Turno' }
            ],
            selectedEmpresas: [],
            selectVLLP: [],
            arrayAllOpt: [],
            optionsVLLP: [],
            selectedVLLP: [],
            selectedTurnos: [],
            radiosSelected: 'V',
            arrayButtons: [
                { 'value': 'I', 'text': 'Iniciadas', 'title': 'Iniciadas', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected': false },
                { 'value': 'F', 'text': 'Finalizadas', 'title': 'Finalizadas', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected': false },
                { 'value': 'P', 'text': 'Pendentes', 'title': 'Pendentes', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected': false },
                { 'value': 'C', 'text': 'Canceladas', 'title': 'Canceladas', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected': false },
            ],
            itemsSubtitleLinha: [
                { 'description': 'Horário programado', 'spanColor': 'light-green', 'icone': '' },
                { 'description': 'Atraso tolerado', 'spanColor': 'orange', 'icone': '' },
                { 'description': 'Atrasado', 'spanColor': 'red', 'icone': '' },
            ],
            itemsSubtitleStatusLinha: [
                { 'description': 'C - Canceladas', 'spanColor': '', 'icone': '' },
                { 'description': 'F - Finalizadas', 'spanColor': '', 'icone': '' },
                { 'description': 'I - Iniciadas', 'spanColor': '', 'icone': '' },
                { 'description': 'P - Pendentes', 'spanColor': '', 'icone': '' },
            ],
            arrayValuesFiltros: [],
            htmlRelatorio: '',
            statusbar: 'info',
            tamanhoRelatorio: 0,
            dadosRelatorio: [],
            iconFlecha: false,
            actived: false,
            idCollapse: '',
            loadingExportar: [false, false, false],
            loadingPanel: false,
            carregando: false,
            selectedEmpresa: [],
            selectedTurno: [],
            selectedRegioes: [],
            regioes: [],
            regioesSelecionadas: [],
            loadingRegioes: false,
            comPontos: false,
            totalizadoresTodosDias: [],
        }
    },

    methods: {
        ...mapGetters(['getMaster']),

        SelectEmpresas(selecionados) {
            this.selectedEmpresas = selecionados;
            this.selectVLLP.clearAll();
            this.dados_filtros();
            this.consultarRegioes();
            this.limparDadosRelatorio();
        },

        /**
         * recebe o valor dos turnos
         * @param selecionados 
         * @author Otávio 🦆
         */
        selectTurnos(selecionados) {
            this.selectedTurnos = selecionados;
            this.limparDadosRelatorio();
        },

        SelectCriadoVLLP(select) {
            this.selectVLLP = select
            this.limparDadosRelatorio();
        },

        changeCheck(check) {
            this.radiosSelected = check;

            if (this.arrayAllOpt.length != 0) {
                this.optionsVLLP = this.arrayAllOpt[check];
            }

            this.limparDadosRelatorio();
        },

        selectLinhasVeiculos(selecionados) {
            this.selectedVLLP = selecionados;
            this.limparDadosRelatorio();
        },

        changeFiltros(value) {
            this.arrayValuesFiltros = value;
            this.limparDadosRelatorio();
        },

        changeData(data) {
            this.datas = data;
            this.limparDadosRelatorio();
        },

        async dados_filtros() {
            this.carregando = true
            var dados = [];
            dados = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['V', 'L', 'LP'])
            this.arrayAllOpt = dados;
            this.changeCheck(this.radiosSelected);
            this.carregando = false
        },

        async gerarRelatorio() {
            this.loadingPanel = true;
            this.htmlRelatorio = '',
                this.dadosRelatorio = [];
            this.dataDoRelatorio = this.datas;
            this.tamanhoRelatorio = 0;

            var data = await new HttpRequest().Post('/coletivos/relatorios/detalhes/linhas/gerar/relatorio',
                {
                    'cliente': this.selectedEmpresas,
                    'data': this.datas,
                    'TipoStatus': this.arrayValuesFiltros,
                    'tipobusca': this.radiosSelected,
                    'selectVeicLI': this.selectedVLLP,
                    'selectTurnos': this.selectedTurnos,
                    'regioes': this.regioesSelecionadas
                });

            this.tamanhoRelatorio = data.data.tamanho;

            const datas = Object.keys(data.data.linhas.datas);
            let linhas = data.data;

            datas.map((data) => {
                this.comPontos = linhas.linhas.datas[data][0].itensRota.length > 0;
            })

            if (this.tamanhoRelatorio > 0) {
                this.dadosRelatorio = linhas.linhas.datas;
                this.totalizadoresTodosDias = linhas.linhas.totalizadores;
            } else {
                this.statusbar = 'error';
            }

            this.loadingPanel = false
        },

        async exportarRelatorio(type) {
            var root = process.env.VUE_APP_ROOT;
            if (this.tamanhoRelatorio > 0) {
                switch (type) {
                    case 'pdf':
                        this.loadingExportar = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingExportar = [false, true, false]
                        break;
                    case 'csv':
                        this.loadingExportar = [false, false, true]
                        break;
                    default:
                        break;
                }
                var data = await new HttpRequest().Post('/coletivos/relatorios/detalhes/linhas/exportar/relatorio',
                    { 'comPontos': this.comPontos, 'arrayDados': JSON.stringify(this.dadosRelatorio), 'tipo': type, 'data': this.dataDoRelatorio, 'totalizadores': this.totalizadoresTodosDias });
                window.open(root + '/' + data.data.local);
            }
            this.loadingExportar = [false, false, false]
        },

        /**
         * Consulta e retorna informações sobre regiões associadas a empresas selecionadas.
         *
         * Esta função realiza uma requisição POST para um endpoint específico, enviando as
         * empresas selecionadas como parâmetro. Os dados retornados são armazenados na variável
         * `this.regioes`, e o estado de carregamento é controlado pela variável `this.loadingRegioes`.
         *  @author Yuri 🇧🇷
         */
        async consultarRegioes() {
            const url = '/coletivos/relatorios/detalhes/linhas/consultar/regioes';
            const obj = {
                "empresas": this.selectedEmpresas,
            };
            this.loadingRegioes = true;

            new HttpRequest().Post(url, obj).then((data) => {
                this.regioes = data.data.regioes;
            }).finally(() => {
                this.loadingRegioes = false;
            })
        },

        /**
         * Atualiza a região selecionada pelo usuário.
         *
         * Esta função é utilizada para alterar o valor de `this.regioesSelecionadas`
         * com base na região selecionada pelo usuário.
         * @param {Object} regiao - Objeto que representa a região selecionada.
         * 
         * 
         * @author Yuri 🇧🇷
         */
        changeRegiao(regiao) {
            this.regioesSelecionadas = regiao;
            this.limparDadosRelatorio();
        },

        /**
         * Função para limpar os dados do relatório, quando qualquer filtro é atualizado
         * 
         * @author Yuri 🇧🇷
         */
        limparDadosRelatorio() {
            this.dadosRelatorio = [];
            this.tamanhoRelatorio = 0;
        }
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optionsEmpresas
        }
    }
})
</script>

<style lang="scss">
#detalhesLinha {
    .buttonFiltrosRotas {
        &.bt-white {
            span {
                &::before {
                    display: none;
                }
            }
        }
    }

    .tbodyDetalhesLinhas {
        background: #fff !important;
    }

    .td {
        font-size: 11px !important;
    }

    .tr {
        font-size: 11px !important;
    }

    .fonteTabelaExterna {
        font-size: 12px !important;
    }

    .th-km {
        flex: 1;
    }

    .fonteTabelaExterna>div {
        display: inline-block;
        text-align: center;
    }

    .fonteTabelaExterna>div:first-child {
        width: 15%;
        padding-right: 8px;

    }

    .fonteTabelaExterna>div:last-child {
        width: 85%;
        padding-left: 8px;
    }

    .color-white {
        background: #fff !important;
    }

    .collapse {
        padding-left: 0px;
        padding-right: 0px;
    }

    .cor-vermelha {
        font-size: 14px;
        color: #e04b6e;
    }

    .cor-verde {
        font-size: 14px;
        color: #53a62d;
    }

    .mensagemChevron {
        color: #e04b6e;
    }

    .alertMsgDL {
        text-align: center;
        color: #e04b6e;
    }

    .btnLegenda {
        position: absolute;
        right: 325px;
        z-index: 9999;
    }
}
</style>
