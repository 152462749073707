import { render, staticRenderFns } from "./TablesSimplesResumido.vue?vue&type=template&id=03e1079a&scoped=true"
import script from "./TablesSimplesResumido.vue?vue&type=script&lang=js"
export * from "./TablesSimplesResumido.vue?vue&type=script&lang=js"
import style0 from "./TablesSimplesResumido.vue?vue&type=style&index=0&id=03e1079a&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e1079a",
  null
  
)

export default component.exports