import { render, staticRenderFns } from "./TabelaDetalhesLinhasSemPontos.vue?vue&type=template&id=076e7547&scoped=true"
import script from "./TabelaDetalhesLinhasSemPontos.vue?vue&type=script&lang=js"
export * from "./TabelaDetalhesLinhasSemPontos.vue?vue&type=script&lang=js"
import style0 from "./TabelaDetalhesLinhasSemPontos.vue?vue&type=style&index=0&id=076e7547&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "076e7547",
  null
  
)

export default component.exports