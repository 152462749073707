<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <tableSimples @fimDaRolagem="pegaProximaPagina">
    <thead>
      <tr>
        <th></th> 
        <th>Motorista</th>
        <th class="coluna-cpf">CPF</th>
        <th>Cartão</th>
        <th>Cliente</th>
        <th class="coluna-placa">Placa</th>
        <th>Evento</th>
        <th>Data/Hora Início</th>
        <th>Data/Hora Fim</th>
        <th>Duração</th>
        <th>Velocidade Máx.</th>
        <th>Valor</th>
        <th>Loc. Inicial</th>
        <th>Loc. Fim</th>
        <th>Lat/Log Inicial</th>
        <th>Lat/Log Final</th>
        <th>Ignição</th>
        <th>RPM</th>
        <th>Bateria</th>
      </tr>
    </thead>

    <tbody v-if="mostrarDados">
      <template ref="tableSize">
        <template v-for="(evento, index) in relatorio">
          <tr class="linhaPlaca" :key="index">
            <td v-b-toggle="'collapse-' + index" class="collapse-cell">
              <b-icon :icon="evento.open ? 'chevron-down' : 'chevron-right'"
                v-if="evento.velocidades && evento.velocidades.length" />
            </td>
            <td>{{ evento.motorista != null ? (evento.motorista) : ('Sem motorista') }}</td>
            <td>{{ evento.cpf != null ? evento.cpf : '' }}</td>
            <td>{{ evento.cartao != null ? evento.cartao : '' }}</td>
            <td>{{ evento.cliente }}</td>
            <td>{{ evento.veiculo }}</td>
            <td>{{ evento.evento }}</td>
            <td>{{ evento.data_hora_inicial }}</td>
            <td>{{ evento.data_hora_final }}</td>
            <td>{{ evento.duracao_evento }}</td>
            <td>{{ evento.velocidadeMaxima ? evento.velocidadeMaxima + ' Km/h' : null }}</td>
            <td>{{ evento.valor_evento }} Km/h</td>
            <td>{{ evento.localizacao_inicial }}</td>
            <td>{{ evento.localizacao_final }}</td>
            <td>
              <div class="text-truncate">
                {{ evento.latitude_inicial }} lat;
              </div>
              <div class="text-truncate">
                {{ evento.longitude_inicial }} long;
              </div>
            </td>
            <td>
              <div class="text-truncate">
                {{ evento.latitude_final }} lat;
              </div>
              <div class="text-truncate">
                {{ evento.longitude_final }} long;
              </div>
            </td>
            <td>
              <div v-if="evento.ignicao !== null" style="width: 50%; margin: auto;">
                <baseIcon v-bind="defineIgnicao(evento.ignicao)" />
              </div>
            </td>
            <td>
              <div v-if="evento.rpm !== null">
                <div style="width: 50%; margin: auto;">
                  <baseIcon style="color: green" v-bind="defineIconeRPM(evento.rpm)" />
                </div>
                <div class="text-truncate">
                  {{ evento.rpm }}
                </div>
              </div>
            </td>
            <td>
              <div v-if="evento.status_bateria !== null">
                <div style="width: 50%; margin: auto;">
                  <baseIcon style="color: green" v-bind="defineIconeBateria(evento.status_bateria)" />
                </div>
                <div class="text-truncate">
                  {{ evento.status_bateria }} %
                </div>
              </div>
            </td>
          </tr>

          <tr :key="`${index}-collapse`" v-if="evento.velocidades && evento.velocidades.length"
            class="collapse_table infoOc nopadding">
            <td colspan="19" class="collapse_table nopadding"> 
              <b-collapse :id="`collapse-${index}`" class="collapse_table">
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="tableCollapse">Horário</th>
                      <th class="tableCollapse">Permitido</th>
                      <th class="tableCollapse">Atingido</th>
                      <th class="tableCollapse">Excedido</th>
                      <th class="tableCollapse">Porcentagem</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(velocidade, i) in evento.velocidades" :key="i" class="infoOc">
                      <td>{{ velocidade.dataevento }}</td>
                      <td>{{ velocidade.velocidadeMaxima }} Km/h</td>
                      <td>{{ velocidade.velocidade }} Km/h </td>
                      <td>{{ velocidade.velocidade - velocidade.velocidadeMaxima  }} Km/h</td>
                      <td>{{ velocidade.porcentagem_excesso }}%</td>
                    </tr>
                  </tbody>
                </table>
              </b-collapse>
            </td>
          </tr>
        </template>
      </template>
    </tbody>
    <tbody v-else>
      <statusBar :statusBar="relatorio.info" :colspanForTd="19" />
    </tbody>
  </tableSimples>
</template>

<script>
import Vue from 'vue'
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { DateTime } from "luxon";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiBattery30,
  mdiBattery60,
  mdiBattery90,
  mdiBattery,
  mdiTarget,
  mdiGaugeEmpty,
  mdiGaugeFull,
  mdiGaugeLow,
  mdiGauge
} from "@mdi/js";

export default Vue.extend({
  name: 'TablesSimplesResumido',

  components: {
    statusBar: require("@/components/Atom/StatusInformation/StatusInformation").default,
    tableSimples: require("@/components/Atom/Table/TableSimples").default,
    baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
  },

  data() {
    return {
      mdiCheckCircle: mdiCheckCircle,
      mdiCloseCircle: mdiCloseCircle,
      mdiBattery30: mdiBattery30,
      mdiBattery60: mdiBattery60,
      mdiBattery90: mdiBattery90,
      mdiBattery: mdiBattery,
      mdiTarget: mdiTarget,
      mdiGaugeEmpty: mdiGaugeEmpty,
      mdiGaugeFull: mdiGaugeFull,
      mdiGaugeLow: mdiGaugeLow,
      mdiGauge: mdiGauge
    }
  },

  props: {
    relatorio: {
      required: true,
    },
    mostrarDados: {
      required: true,
    },
    alertas: {
      required: true
    },
  },

  methods: {
    /**
     * Essa função pega a próxima pagina 
     * e faz a requisição da mesma.
     * Depois eu faço umas gambiarrinhas para
     * poder juntar os valores hehe...
     * Sei que dava para melhorar essas função 
     * futuramente ._.
     * @author Marcos
    */
    pegaProximaPagina() {
      if (!this.loading && this.next_page && this.relatorio.dados[0] != undefined) {
        // eslint-disable-next-line vue/no-mutating-props
        this.relatorio.loading = true
        this.loading = true;
        let obj = this.paramsCopia;
        let regex = /\d+.?$/
        let number = regex.exec(this.next_page)
        let urlProximaPagina = this.url + 'gerar?page=' + number[0]
        new HttpRequest().Post(urlProximaPagina, obj)
          .then((response) => {
            this.next_page = response.data.next_page_url ?? false
            const dados = Object.values(response.data.data)
            const dadosSemUltimo = dados.slice(0, -1)
            // eslint-disable-next-line vue/no-mutating-props
            this.relatorio.dados[0].registros =
              this.relatorio.dados[0].registros.concat(dadosSemUltimo)
          })
          .catch(() => {
            conectionError()
          })
          .finally(() => {
            // eslint-disable-next-line vue/no-mutating-props
            this.relatorio.loading = false;
            this.loading = false;
          });
      }
    },

    /** 
     * formata a hora de  yyyy-LL-dd HH:mm:ss para dd/LL/yyyy HH:mm:ss
     * @author Otávio 🦆 
    */
    formataDataHora(dataHora) {
      if (dataHora) {
        var data = DateTime.fromFormat(dataHora, "yyyy-LL-dd HH:mm:ss").toFormat("dd/LL/yyyy HH:mm:ss");
        return data;
      }
      return null;
    },

    /**
     * Apenas valida se o motivo de transmissão
     * esta dentro do array de alertas ou se o registro é uma tratativa.
     * Se estiver, retorna a cor da fonte em vermelho.
     */
    validaAlerta(registro) {
      if (this.alertas.includes(registro.evento)) return "color:#D94D45";
      return "";
    },

    decideIconeMotivoTransmissao(origem) {
      switch (origem) {
        case "bilhetes":
          return this.mdiCarConnected;
        case "comandos":
          return this.mdiCellphoneMessage;
        case "tratativas_alerta":
          return this.mdiDrawPen;
        default:
          break;
      }
    },

    defineIgnicao(ignicao) {
      if (ignicao) {
        return {
          size: 18,
          icon: mdiCheckCircle,
          style: "color: #5BC936",
        };
      } else {
        return {
          size: 18,
          icon: mdiCloseCircle,
          style: "color: #D94D45",
        };
      }
    },

    defineIconeBateria(porcentagemBateria) {
      if (porcentagemBateria > 90) {
        return {
          icon: mdiBattery,
          size: 14,
          style: "color: #5BC936",
        };
      } else if (porcentagemBateria <= 90 && porcentagemBateria > 60) {
        return {
          icon: mdiBattery90,
          size: 14,
          style: "color: #5BC936",
        };
      } else if (porcentagemBateria <= 60 && porcentagemBateria > 30) {
        return {
          icon: mdiBattery60,
          size: 14,
          style: "color: #DECC47",
        };
      } else if (porcentagemBateria <= 30) {
        return {
          icon: mdiBattery30,
          size: 14,
          style: "color: #D94D45",
        };
      }
    },

    defineIconeRPM(RPM) {
      if (RPM >= 1000) {
        return {
          icon: mdiGaugeEmpty,
          size: 19,
          style: "color: #000",
        };
      } else if (RPM >= 2000) {
        return {
          icon: mdiGaugeLow,
          size: 19,
          style: "color: #000",
        };
      } else if (RPM >= 3000) {
        return {
          icon: mdiGauge,
          size: 19,
          style: "color: #000",
        };
      } else if (RPM >= 4000) {
        return {
          icon: mdiGaugeFull,
          size: 19,
          style: "color: #000",
        };
      }
    },

    linkGoogleMaps(value) {
      let latlng = value?.split(",") ?? [0, 0];
      window.open(`https://www.google.com/maps/place/${latlng[0]},${latlng[1]}`);
    },
  },
})
</script>

<style lang="css" scoped>
.linhaPlaca {
  margin-top: 10px;
  background-color: #fff !important;
}

.coluna-cpf {
  min-width: 130px;
  width: 130px;
}

.coluna-placa {
  min-width: 100px;
  width: 100px;
}

.placaExcesso {
  width: 300px;
  background-color: #777;
  padding-left: 0px;
  padding-right: auto;
  color: white;
  padding-top: 4x !important;
  font-size: 15px !important;
  text-align: center !important;
  margin-top: 15px;
  height: 30px;
  vertical-align: middle;
  display: table-cell;
}

.trStyle {
  font-size: 12px;
  background: #eee !important;
}

td {
  padding-left: 5px;
  background: #eee !important;
}

.botao::before {
  font-size: 16px;
  cursor: pointer;
}


.corIcone {
  color: #2196f3;
}
.collapse_table {
  td {
    background-color: #fff !important;
  }
  background-color: #fff !important;
  border: none !important;
  margin: none !important;
  padding: none !important;
}

.tableCollapse {
  border-bottom: 3px solid #e2e5e9;
  background: #fff;
}

.tableCollapseLocal {
  border-bottom: 3px solid #e2e5e9; 
  background: #fff;
  width: 21%;
}

.infoOc {
  font-size: 12px;
  padding: 0 !important;
  background: #fff !important;
  td {
    padding-left: 5px;
    white-space: nowrap;
  }
}
</style>
